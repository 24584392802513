<template>
  <div>
    <a-form-model :model="form">
      <a-row :gutter="16">
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="sourceType">
            <source-type-select v-model="form.sourceType"></source-type-select>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="severity">
            <severity-select v-model="form.severity"></severity-select>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="project">
            <project-select v-model="form.project" :label-in-value=true></project-select>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="name">
            <a-input v-model="form.name" :allow-clear="true" :max-length="128" placeholder="请输入告警事件名称"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col s:xs="24" :sm="12" :xl="4">
          <a-form-model-item>
            <a-button icon="search" type="primary" @click="search">查询</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-card :bordered="false">
      <a-space style="margin-bottom: 16px;">
        <template v-if="permission.update">
          <a-button
            :disabled="disabled"
            icon="edit"
            type="link"
            @click="$refs.batchUpdateForm.show(rowSelection.selectedRowKeys)"
            :style="{ color: disabled ? '' : '#faad14' }"
          >
            编辑
          </a-button>
          <batch-update-form ref="batchUpdateForm" @ok="() => { this.fetch(); this.rowSelection.selectedRowKeys = [] }"></batch-update-form>
        </template>
      </a-space>

      <a-table
        :columns="columns"
        :data-source="dataSource"
        :loading="loading"
        :pagination="false"
        row-key="id"
        :row-selection="rowSelection"
        :scroll="{ x: scrollX }"
        @change="change"
        style="margin-bottom: 16px;"
      >
        <template slot="name" slot-scope="record">
          <a-tooltip placement="topLeft" :title="record.name">
            <a @click="$router.push({ name: 'AlertEventDetail', params: { id: record.id } })">{{ record.name }}</a>
          </a-tooltip>
        </template>
        <template slot="sourceType" slot-scope="record">
          <a-tooltip v-if="record.source" :title="record.source.name">
            <a-tag color="blue">{{ $t(`source_type.${record.source_type}`) }}</a-tag>
            <router-link :to="{ name: routerName(record.source_type), params: { id: record.source.id } }">
              <span>{{ record.source.name }}</span>
            </router-link>
          </a-tooltip>
          <span v-else>-</span>
        </template>
        <template slot="severity" slot-scope="record">
          <severity-tag :severity="record"></severity-tag>
        </template>
        <template slot="duration" slot-scope="record">{{ duration(record.triggered_at, record.recovered_at) }}</template>
        <template slot="project" slot-scope="record">
          <a-tooltip v-if="record" placement="topLeft" :title="record.name">{{ record.name }}</a-tooltip>
          <span v-else>-</span>
        </template>
        <template slot="category" slot-scope="record">
          <a-tooltip v-if="record" placement="topLeft" :title="record.name">{{ record.name }}</a-tooltip>
          <span v-else>-</span>
        </template>
        <template slot="recovered" slot-scope="record">
          <boolean-status-badge false-name="告警" true-name="恢复" :value="record"></boolean-status-badge>
        </template>
        <template v-if="permission.update" slot="operation" slot-scope="record">
          <a @click="$refs.updateForm.show(record.id)" style="color: #faad14;">
            <a-space style="user-select: none;">
              <a-icon type="edit"></a-icon>
              <span>编辑</span>
            </a-space>
          </a>
        </template>
      </a-table>
      <update-form v-if="permission.update" ref="updateForm" @ok="fetch"></update-form>

      <div style="height: 32px;">
        <span v-if="rowSelection.selectedRowKeys.length" style="line-height: 32px;">{{ `已选中 ${rowSelection.selectedRowKeys.length} 条` }}</span>
        <a-pagination
          v-model="form.page"
          :pageSize="form.pageSize"
          :show-size-changer="true"
          :show-total="t => `共 ${t} 条`"
          :total="total"
          @change="page => { this.form.page = page; this.fetch() }"
          @showSizeChange="(_, pageSize) => { this.form.page = 1; this.form.pageSize = pageSize; this.fetch() }"
          style="float: right;"
        ></a-pagination>
      </div>
    </a-card>
  </div>
</template>

<script>
import moment from 'moment'
import { getAlertEventList } from '@/api/alert-event'
import BooleanStatusBadge from '@/components/badge/BooleanStatusBadge'
import ProjectSelect from '@/components/select/ProjectSelect'
import SeveritySelect from '@/components/select/SeveritySelect'
import SourceTypeSelect from '@/components/select/SourceTypeSelect'
import SeverityTag from '@/components/tag/SeverityTag'
import { hasPermission, humanTime, getSourceRouterName } from '@/utils'
import UpdateForm from '../modules/UpdateForm'
import BatchUpdateForm from './modules/BatchUpdateForm'

export default {
  name: 'AlertEventList',
  components: {
    BatchUpdateForm,
    BooleanStatusBadge,
    ProjectSelect,
    SeveritySelect,
    SeverityTag,
    SourceTypeSelect,
    UpdateForm
  },
  data () {
    return {
      form: {
        project: undefined,
        sourceType: undefined,
        severity: undefined,
        name: '',
        page: 1,
        pageSize: 10
      },
      dataSource: [],
      total: 0,
      loading: false,
      rowSelection: {
        selectedRowKeys: [],
        onChange: selectedRowKeys => {
          this.rowSelection.selectedRowKeys = selectedRowKeys
        }
      }
    }
  },
  computed: {
    permission () {
      return {
        update: hasPermission('alert_event.update')
      }
    },
    columns () {
      const columns = [
        { title: '告警事件名称', width: 200, ellipsis: true, scopedSlots: { customRender: 'name' } },
        { title: '事件源', width: 200, ellipsis: true, scopedSlots: { customRender: 'sourceType' } },
        { dataIndex: 'severity', title: '严重性', width: 160, scopedSlots: { customRender: 'severity' } },
        { dataIndex: 'triggered_at', title: '触发时间', width: 200 },
        { dataIndex: 'recovered_at', title: '恢复时间', width: 200 },
        { title: '持续时间', width: 160, scopedSlots: { customRender: 'duration' } },
        { dataIndex: 'project', title: '所属项目', width: 200, ellipsis: true, scopedSlots: { customRender: 'project' } },
        { dataIndex: 'category', title: '分类', width: 200, ellipsis: true, scopedSlots: { customRender: 'category' } },
        {
          dataIndex: 'recovered',
          title: '状态',
          width: 100,
          fixed: 'right',
          scopedSlots: { customRender: 'recovered' },
          defaultFilteredValue: [],
          filters: [
            { text: '告警', value: 'false' },
            { text: '恢复', value: 'true' }
          ],
          filterMultiple: false
        }
      ]
      if (this.permission.update) {
        columns.push({ title: '操作', width: 160, fixed: 'right', scopedSlots: { customRender: 'operation' } })
      }
      return columns
    },
    scrollX () {
      let sum = 0
      this.columns.forEach(column => { sum += column.width })
      return sum
    },
    disabled () {
      return this.rowSelection.selectedRowKeys.length === 0
    }
  },
  mounted () {
    const params = this.$route.params
    if (params.severity) this.form.severity = params.severity
    if (params.sourceType) this.form.sourceType = params.sourceType
    if (params.project) this.form.project = params.project
    if (params.recovered) {
      this.form.recovered = params.recovered
      this.columns.filter(column => {
        if (column.dataIndex === 'recovered') {
          column.defaultFilteredValue = [params.recovered]
        }
      })
    }
    this.fetch()
  },
  methods: {
    fetch () {
      const params = {
        page: this.form.page,
        page_size: this.form.pageSize
      }
      if (this.form.project) params.project_id = this.form.project.key
      if (this.form.sourceType) params.source_type = this.form.sourceType
      if (this.form.severity) params.severity = this.form.severity
      if (this.form.name) params.name = this.form.name
      if (this.form.recovered) params.recovered = this.form.recovered
      this.loading = true
      getAlertEventList(params).then(res => {
        const data = res.data
        this.dataSource = data.data
        this.total = data.total
      }).finally(() => {
        this.loading = false
      })
    },
    routerName (sourceType) {
      return getSourceRouterName(sourceType)
    },
    search () {
      this.form.page = 1
      this.rowSelection.selectedRowKeys = []
      this.fetch()
    },
    change (_, filters) {
      this.form.recovered = filters.recovered[0]
      this.fetch()
    },
    duration (triggeredAt, recoveredAt) {
      let second = 0
      if (recoveredAt) second = (moment(recoveredAt) - moment(triggeredAt)) / 1000
      else second = (moment() - moment(triggeredAt)) / 1000
      return humanTime(second)
    }
  }
}
</script>
